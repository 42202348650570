import provinces from './provinces.js'
import citys from './cities.js'
import areas from './areas.js'
import pca from './pca.js'
import pc from './pc.js'

// 获取下一级数据
const getNextData = function (code, index) {
  let data = []
  if (index === 0) {
    data = provinces
  } else if (index === 1) {
    data = citys.filter((item) => item.provinceCode === code)
  } else {
    data = areas.filter((item) => item.cityCode === code)
  }
  return data
}
const getName = function (code, index) {
  let name = ''
  switch (index) {
    case 0:
      name = provinces.find(item => item.code === code).name
      break;
    case 1:
      name = citys.find(item => item.code === code).name
      break;
    case 2:
      name = areas.find(item => item.code === code).name
      break;
    default:
      break;
  }
  return name
}
export {
  provinces,
  citys,
  areas,
  getNextData,
  getName,
  pca,
  pc
}